import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'





Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    name:'index',
    component:() => import("../views/index/index.vue"),
    // children:[{
    //   path:'copycheck',
    //   name:'copycheck',
    //   component:() => import("../views/copycheck/copycheck.vue")
    // }]
  },
  {
    path:'/copycheck',
    name:'copycheck',
    component:() => import("../views/copycheck/copycheck.vue")
  },
  {
    path:'/weipu',
    name:'weipu',
    component:() => import("../views/weipu/weipi.vue")
  },
  {
    path:'/report',
    name:'report',
    component:() => import("../views/report/report.vue")
  },
  {
    path:'/list',
    name:'list',
    component:() => import("../views/report/list.vue")
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
